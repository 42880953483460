// Styles.js
import Settings from "./settings";
import styled from "styled-components";

export const ChatBotContainer = styled.div`
  font-family: ${Settings.primaryFont};
  margin-top: 100px;
  display: flex;
  align-items: center; /* Center horizontally */
  flex-direction: row; /* Set the flex-direction to row */
  p.WelcomeMessage {
    font-size: 25px;
    display : flex;
    align-items : center ;
    gap : 5px;
  }
  p.BuildAi {
    color: ${Settings.primaryColor};
    align-items : center ;
    display:flex;
    font-size: 27px;
  }
`;