import React from "react";
import Lottie from "react-lottie";
import ChatAnimationData from "../../../public/icons/404/ChatAnimation.json"; // Replace with the actual path
import { ChatBotContainer } from "./Styles";
// import BuildAiLogo from "../../assets/icons/LoginLogo.svg";
const ChatAnimation = () => {
  const animationOptions = {
    animationData: ChatAnimationData,
    loop: true,
    autoplay: true,
  };

  return (
    <ChatBotContainer>
      <Lottie
        options={animationOptions}
        height={300} // Set the height according to your design
        width={300} // Set the width according to your design
      />
    </ChatBotContainer>
  );
};

export default ChatAnimation;
