"use client"

import { useEffect } from "react";
import { useLanguage } from "@/context/LanguageContext";
import {translate} from "@/Hooks/translate";
import styles from "./404.module.css"

import ChatAnimation from "./Lottie/ChatAnimation";

const Custom404  = () => {

  const {language , changeLanguage } = useLanguage();



  useEffect(() => {
    // Set a timeout to redirect after 8 seconds
    const timer = setTimeout(() => {
      window.location.href = `/${language}`;
    }, 8000);

    // Clean up the timeout if the component is unmounted before the time is up
    return () => clearTimeout(timer);
  }, [language]);

    return (
        <div className={`${styles.container}`} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '650px' }}>
            <ChatAnimation />
            <h1 className='mb-2 text-xl'>{translate('not_found.title' , language)}</h1>
            <p className='text-center'>{translate('not_found.text' , language)}</p>
            <a href={`/${language}`} className='bg-[#009D90] hover:bg-[#167c74] text-white mt-4 rounded px-4 py-2'>
                {translate('not_found.link' , language)}
            </a>
        </div>
    );
}
 
export default Custom404 ;