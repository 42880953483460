// settings.js
const Settings = {
  // apiUrl: process.env.REACT_APP_API_BASE_URL || 'http://127.0.0.1:8000/api/v1/',
  apiUrl: 'https://api.buildai.company/api/v1/',
  primaryColor: '#624BFF', // Set your primary color here
  primaryColorGray: '#868E96', // Set your primary font here 
  primarySideBarColorGray: '#919EAB', // Set your primary font here 
  primaryNoneWhiteColor: '#eff2f4', // Set your primary font here 
  primaryFont: "'Roboto', sans-serif", // Set your primary font here 
  
};

export default Settings;
